import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import BlogWrapper from "../../../components/article/BlogWrapper";
import StateBusinessBankBlogContent from "../../../components/state-business-bank/StateBusinessBankAccountRequirementsBlogContent";
import { Link } from "gatsby";

// NOTE: for apostrophes or single quotes in the title to render, please use an ASCII single quote and wrap the string in backticks.
const SEO = {
  MetaTitle: `What's Needed to Open a Business Bank Account in Washington`,
  MetaDescription:
    "Learn what's needed to open a business bank account in Washington including key documents needed. Open a Washington business bank account today with WaFd Bank.",
  Schema: {
    "@id": "https://www.wafdbank.com/blog/small-business/washington-business-bank-account-requirements",
    "@type": "Article",
    image: "https://www.wafdbank.com/images/adsearch/blogs/og-buisness-account-washington-082724.jpg",
    "@context": "https://schema.org",
    headline: "What's Needed to Open a Business Bank Account in Washington",
    description:
      " Learn what's needed to open a business bank account in Washington including key documents needed. Open a Washington business bank account today with WaFd Bank."
  }
};

const BeforeStartingOwnBusinessBlog = () => {
  const cards = [
    "/blog/small-business/choosing-location-for-business",
    "/blog/small-business/small-business-cybersecurity-tips",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ];

  const considerContent = (
    <p>
      Washington is home to many well-known fortune 500 companies like Boeing, Microsoft, Amazon, Starbucks, Costco and
      many others making it a breeding ground for small business innovation. WaFd Bank is one of those businesses
      getting our start right in Seattle. We are a regional bank that provides{" "}
      <Link to="/business-banking/small-business" id="blog-small-businesses-link">
        business banking solutions
      </Link>{" "}
      so you can easily manage finances and focus on growing your business. A variety of services and tools we offer can
      help you get your business off the ground (or to the next level) including:
    </p>
  );

  const stateData = {
    introText:
      "The Evergreen state boasts 645,000 small businesses employing 1.4 million Washingtonians. In fact, 99.5% of companies in Washington are small businesses. If you've started your own business or are thinking about it, know that it's important to keep your own business funds separate from your personal finances. It helps you stay organized and focus on your business priorities, and as an added bonus, it helps to protect your personal assets, too. So, what exactly do you need to know? Read on!",
    image: (
      <StaticImage
        src="../../../images/blogs/small-business/state-business-bank-account-requirements/thumbnail-business-account-washington-082724.jpg"
        alt="Flying view of Washington."
        placeholder="blurred"
        quality="100"
      />
    ),
    considerContent
  };

  return (
    <BlogWrapper
      cards={cards}
      title={"What Is Needed to Open a Business Bank Account in Washington"}
      pageUrl={"/blog/small-business/washington-business-bank-account-requirements"}
      ogImage={"https://www.wafdbank.com/images/adsearch/blogs/og-buisness-account-washington-082724.jpg"}
      contactInfo={"LocalBranch"}
      seoData={SEO}
    >
      <StateBusinessBankBlogContent {...stateData} />
    </BlogWrapper>
  );
};

export default BeforeStartingOwnBusinessBlog;
